import styled from 'styled-components';

export const Text = styled.h3`
  margin: 4rem 0 0rem;
  display: block;
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.2;
  &:hover {
    color: #40e976;
    transition: color 0.2s ease;
  }
`;

export const TextDesc = styled.p`
  margin: 2rem 0 0rem;
  display: block;
  font-style: italic;
  font-size: 2.0rem;
  line-height: 1.2;
`;
