import React from 'react';
import { Text, TextDesc } from './projects.css';
import PropTypes from 'prop-types';

const Projects = ({ title, desc }) => {
  return (
    <div>
      <Text>{title}</Text>
      <TextDesc>{desc}</TextDesc>
    </div>
  );
};

Projects.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default Projects;
